const API_VERSION = "v1"
const CLIENT_SIDE_BASE_URL = `/api/${API_VERSION}`
const SERVER_SIDE_BASE_URL = `${process.env.NEXT_PUBLIC_SERVER_SIDE_API_URL}/${API_VERSION}`

export const NEW_CLIENT_SIDE_BASE_URL = ""
export const NEW_SERVER_SIDE_BASE_URL = `${process.env.NEXT_PUBLIC_SERVER_SIDE_API_URL}`

enum REQUEST_TYPE {
  CLIENT = "CLIENT",
  SERVER = "SERVER",
}

export { API_VERSION, CLIENT_SIDE_BASE_URL, REQUEST_TYPE, SERVER_SIDE_BASE_URL }
