import React from "react"
import { apiRoot } from "@app/api"

function useLinkImageInView(
  size: number,
  typeSize: string,
  photo_path: string,
  imgFormat: string,
  imageProxyHost: string,
  contentType: string,
  inView: boolean
) {
  const [srcImage, setSrcImage] = React.useState(null)
  const [isReadyImage, setIsReadyImage] = React.useState(false)

  const controller = new AbortController()

  const getSrcImage = function (size, typeSize, linkImage, imgFormat, imageProxyHost, contentType) {
    const srcImageFormat = contentType !== "image/gif" ? imgFormat : ""
    const imageSizeFormat = `=${typeSize}${size}${srcImageFormat}`

    return linkImage.includes(imageProxyHost) ? linkImage : `${linkImage}${imageSizeFormat}`
  }

  const getImage = async (size, photo_path, signal) => {
    const request = apiRoot()
    const NEXT_PUBLIC_URL_IMAGE_SOURCE = process.env.NEXT_PUBLIC_URL_IMAGE_SOURCE
    return await request
      .get(NEXT_PUBLIC_URL_IMAGE_SOURCE, {
        signal,
        params: {
          size,
          photo_path,
        },
      })
      .catch((error) => {
        console.log("useLinkImageInView network error:", error)
      })
  }

  async function getLinkImage({ size, photo_path, contentType, signal }) {
    const linkImage = await getImage(size, photo_path, signal)

    if (!linkImage) return

    setSrcImage(getSrcImage(size, typeSize, linkImage.data, imgFormat, imageProxyHost, contentType))
    setIsReadyImage(linkImage.data)
  }

  React.useEffect(() => {
    if (inView && !isReadyImage) {
      getLinkImage({ size, photo_path, contentType, signal: controller.signal })
    }

    return () => {
      controller.abort()
    }
  }, [size, photo_path, contentType, inView])

  return { srcImage, isReadyImage }
}

export { useLinkImageInView }
