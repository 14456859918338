import * as React from "react"

function useKeyPress(keyCode: string, callback: () => void): void {
  React.useEffect(() => {
    function handleKeyUp(event: any) {
      if (event.code === keyCode) {
        callback()
      }
    }

    window.addEventListener("keyup", handleKeyUp)

    return () => window.removeEventListener("keyup", handleKeyUp)
  }, [])
}

export { useKeyPress }
