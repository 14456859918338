import { useEffect } from "react"

import { useRouteChange } from "@app/utils"

function useSetTheme(value) {
  const THEME_DARK = "theme-dark"
  const THEME_LIGHT = "theme-light"

  useRouteChange((url: string) => {
    if (url.indexOf("/gallery/") <= -1 && url.indexOf("/favorites-list/") <= -1) {
      document.querySelector("body").classList.remove(THEME_DARK)
      document.querySelector("body").classList.add(THEME_LIGHT)
    }
  })

  useEffect(() => {
    document.querySelector("body").classList.remove(THEME_DARK)
    document.querySelector("body").classList.remove(THEME_LIGHT)
    document.querySelector("body").classList.add(`theme-${value}`)
  }, [value])
}

export { useSetTheme }
