// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"
import { isInjectedCode } from "./lib"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || "https://0ed43a659f734bdfa267176312bc15e7@o287997.ingest.sentry.io/5435596",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.0,
  enabled: process.env.NODE_ENV === "production",
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    "Script error.",
    // Avast extension error
    "_avast_submit",
    "Network request failed",
    "Failed to fetch",
    "NetworkError",
    "withrealtime/messaging",
    /gtag/,
    /illegal/,
  ],
  denyUrls: [
    // Исключение URL-адресов расширений Chrome extensions
    /chrome-extension:\/\/[a-zA-Z0-9]+/i,
    /extensions\//i,
    /^chrome:\/\//i,
    // Google Adsense
    /pagead\/js/i,
    // GTM
    /googletagmanager\.com\/gtm\.js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Яндекс Метрика
    /mc\.yandex\.ru\/metrika\/tag\.js/i,
    // Other plugins
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  beforeSend(event) {
    try {
      // Фильтрация ошибок из node_modules
      if (event.exception && event.exception.values) {
        for (let exception of event.exception.values) {
          if (exception.stacktrace && exception.stacktrace.frames) {
            for (let frame of exception.stacktrace.frames) {
              if (frame.filename && frame.filename.includes("node_modules")) {
                return null
              }
            }
          }
        }
      }

      if (
        isInjectedCode(event) ||
        event.exception.values[0].stacktrace.frames[0].filename === `<anonymous>` ||
        event.exception.values[0].stacktrace.frames.some(
          (frame) =>
            frame.filename &&
            (frame.filename.startsWith("chrome-extension://") || frame.filename.includes("mc.yandex.ru"))
        )
      ) {
        return null
      }
    } catch (error) {
      console.log(error)
    }

    return event
  },
})
