const forceDownloadFile = (url: string, name?: string) => {
  const downloadLink = document.createElement("a")
  downloadLink.setAttribute("download", "")
  downloadLink.href = url
  if (name) downloadLink.download = name
  document.body.appendChild(downloadLink)
  downloadLink.click()
  downloadLink.remove()
}

export { forceDownloadFile }
