import { useUserAgent as useNextUserAgent, UserAgent } from "next-useragent"

import { isClient } from "@app/utils"

function useUserAgent(userAgentString: string) {
  const userAgent: null | UserAgent = useNextUserAgent(userAgentString || (isClient && window.navigator.userAgent))

  return userAgent
}

export { useUserAgent }
