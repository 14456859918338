import React from "react"
import { apiRoot } from "@app/api"

function useLinkVideoInView(video_path: string, contentType: string, inView: boolean) {
  const [srcVideo, setSrcVideo] = React.useState(null)
  const [isReadyVideo, setIsReadyVideo] = React.useState(false)

  const controller = new AbortController()

  const getVideo = async (video_path, signal) => {
    const request = apiRoot()
    const NEXT_PUBLIC_URL_IMAGE_SOURCE = process.env.NEXT_PUBLIC_URL_IMAGE_SOURCE
    return await request
      .get(NEXT_PUBLIC_URL_IMAGE_SOURCE, {
        signal,
        params: {
          video_path,
        },
      })
      .catch((error) => {
        console.log("useLinkVideoInView network error:", error)
      })
  }

  async function getLinkVideo({ video_path, signal }) {
    const linkVideo = await getVideo(video_path, signal)

    if (!linkVideo) return

    setSrcVideo(linkVideo.data)
    setIsReadyVideo(true)
  }

  React.useEffect(() => {
    if (inView && !isReadyVideo) {
      const fetchLinkVideo = async () => {
        await getLinkVideo({ video_path, signal: controller.signal })
      }

      fetchLinkVideo().catch((error) => console.log(error))
    }

    return () => {
      controller.abort()
    }
  }, [video_path, contentType, inView])

  return { srcVideo, isReadyVideo }
}

export { useLinkVideoInView }
