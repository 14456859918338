function lockScroll(): void {
  const body = document.body
  const scrollY = window.scrollY
  body.setAttribute("style", `top: -${scrollY}px; width: 100%; height: 100vh; overflow-y: hidden; position: fixed`)
}

function restoreScroll(): void {
  const body = document.body
  const scrollY = body.style.top
  body.style.width = ""
  body.style.height = ""
  body.style.overflowY = ""
  body.style.position = ""
  body.style.top = ""
  window.scrollTo(0, parseInt(scrollY || "0") * -1)
}

export { lockScroll, restoreScroll }
