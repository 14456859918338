import * as React from "react"

function useToggleSelector() {
  const [isDragActionUp, setIsDragActionUp] = React.useState(false)
  const [isDragActionDown, setIsDragActionDown] = React.useState(false)
  let xDown = null
  let yDown = null

  function getTouches(e) {
    return e.touches || e.originalEvent.touches
  }

  function handleTouchStart(e) {
    const firstTouch = getTouches(e)[0]
    xDown = firstTouch.clientX
    yDown = firstTouch.clientY
  }

  function handleTouchMove(e) {
    if (!xDown || !yDown) {
      return
    }

    let xUp = e.touches[0].clientX
    let yUp = e.touches[0].clientY

    let xDiff = xDown - xUp
    let yDiff = yDown - yUp

    if (Math.abs(xDiff) < Math.abs(yDiff)) {
      if (yDiff > 0) {
        setIsDragActionUp(true)
        setIsDragActionDown(false)
      } else {
        setIsDragActionUp(false)
        setIsDragActionDown(true)
      }
    }

    /* reset values */
    xDown = null
    yDown = null
  }

  function handleTouchEnd(e) {
    setIsDragActionUp(false)
    setIsDragActionDown(false)
  }

  return { isDragActionUp, isDragActionDown, handleTouchStart, handleTouchMove, handleTouchEnd }
}

export { useToggleSelector }
