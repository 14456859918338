import { Logo } from "@app/features/maintenance/components"

import styles from "./maintenance.module.scss"

const Maintenance = ({ children }) => {
  return (
    <div className={styles["root"]}>
      <div className={styles["wrapper"]}>
        <a href="https://vigbo.com/galleries" className={styles["link"]}>
          <Logo width="48" height="48" fill="#afafaf" />
        </a>
        <h1 className={styles["title"]}>{children}</h1>
      </div>
    </div>
  )
}

export { Maintenance }
