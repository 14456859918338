const IMAGE_PROXY_HOST = "image-service.vigbo.tech"
const IMAGE_STORAGE_ORIGINAL = "https://storage.googleapis.com/vigbo"
const SIZE = {
  original: 0,
  originalIos: "0-d",
  web: 2500,
  md: 768,
  xs: 250,
  xxl: 2500,
  xl: 1920,
  l: 1440,
  m: 768,
  s: 320,
  "xxl@2": 5000,
  "xl@2": 3840,
  "l@2": 2880,
  "m@2": 1536,
  "s@2": 640,
}
const COLORS = {
  white: "#fff",
  black: "#000",
  smokeScreen: "#AFAFAF",
  nickel: "#929292",
}

export { IMAGE_PROXY_HOST, IMAGE_STORAGE_ORIGINAL, SIZE, COLORS }
