import jwtDecode from "jwt-decode"
import { parseCookies, setCookie, destroyCookie } from "nookies"

import { COOKIES_MAX_AGE } from "@app/constants"
import { ACCESS_TOKEN_KEY, ROLE_CLIENT_KEY } from "@app/features/user/constants"

function setAccessTokenToLocalStorage(token) {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, token)
}

function getAccessTokenFromLocalStorage() {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY)
}

function setAccessTokenToCookies(token, ctx = null) {
  setCookie(ctx, ACCESS_TOKEN_KEY, token, {
    maxAge: COOKIES_MAX_AGE,
    path: "/",
  })
}

function getAccessTokenFromCookies(ctx = null) {
  const cookies = parseCookies(ctx)

  if (cookies?.[ACCESS_TOKEN_KEY]) {
    return cookies[ACCESS_TOKEN_KEY]
  }

  return null
}

function destroyAccessTokenFromCookies(ctx = null) {
  setAccessTokenToCookies("", ctx)
  destroyCookie(ctx, ACCESS_TOKEN_KEY, { path: "/" })
}

function verifyAccessToken(accessToken) {
  const decodedAccessToken = jwtDecode(accessToken)
  const currentTime = new Date().getTime() / 1000

  const isNotExpired = currentTime < decodedAccessToken.exp
  const isClientRole = decodedAccessToken.roles.includes(ROLE_CLIENT_KEY)
  const hasId = decodedAccessToken.hasOwnProperty("id")

  if (isNotExpired && isClientRole && hasId) {
    return true
  } else {
    return false
  }
}

function checkAccessToken(ctx = null) {
  if (getAccessTokenFromCookies(ctx) && verifyAccessToken(getAccessTokenFromCookies(ctx))) {
    return true
  } else {
    return false
  }
}

export {
  setAccessTokenToLocalStorage,
  getAccessTokenFromLocalStorage,
  verifyAccessToken,
  setAccessTokenToCookies,
  getAccessTokenFromCookies,
  destroyAccessTokenFromCookies,
  checkAccessToken,
}
