import { useRouter } from "next/router"

const useClearQueryParameter = () => {
  const router = useRouter()

  return (queryKey: string) => {
    if (typeof router.query[queryKey] === "undefined") return
    const { [queryKey]: _, ...query } = router.query

    router
      .replace({ pathname: router.pathname, query }, undefined, {
        shallow: true,
      })
      .catch((error) => console.log(error))
  }
}

export { useClearQueryParameter }
