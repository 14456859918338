import { api } from "@app/api"
import { REQUEST_TYPE } from "@app/api/constants"

export const isMaintenanceEnabled: Function = async () => {
  try {
    return false
    const { data } = await api(REQUEST_TYPE.SERVER).get(`/maintenance`)
    return data.data.linkService
  } catch (error) {
    return true
  }
}
