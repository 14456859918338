import React from "react"
import axios from "axios"
import { TEST_IMAGE_URL } from "@app/features/google-image-service-status/constants"

const useGoogleImageServiceStatus = () => {
  const [isAvailable, setIsAvailable] = React.useState(null)

  const requestImage = React.useCallback(async () => {
    try {
      await axios.get(`${TEST_IMAGE_URL}?timestamp=${new Date().getTime()}`, {
        timeout: 1000,
      })
      setIsAvailable(true)
    } catch (error) {
      setIsAvailable(false)
    }
  }, [])

  React.useEffect(() => {
    requestImage().catch((error) => console.log(error))
  }, [requestImage])

  return isAvailable
}

export { useGoogleImageServiceStatus }
