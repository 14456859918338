import { api } from "@app/api"

async function fetchGeneralSettings({ requestType, ctx = null, domain }) {
  try {
    const { data } = await api(requestType, ctx).get(`/photographer/${domain}/settings`)

    return data.data
  } catch (error) {
    console.error(error.response)

    return null
  }
}

export { fetchGeneralSettings }
