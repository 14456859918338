import { useEffect, useState } from "react"
import { getMobileOperatingSystem } from "../get-mobile-operating-system"

const useIsDownloadRestrictionMessenger = () => {
  const [isDownloadRescritctionMessenger, setIsDownloadRescritctionMessenger] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const userAgent = navigator.userAgent
      const isIOS = getMobileOperatingSystem() === "iOS"
      let isDownloadRestriction = false
      if (isIOS) {
        isDownloadRestriction =
          !!window.TelegramWebviewProxy || /FBAN|FBAV/.test(userAgent) || /Instagram/.test(userAgent)
      }
      setIsDownloadRescritctionMessenger(isDownloadRestriction)
    }
  }, [])

  return isDownloadRescritctionMessenger
}

export { useIsDownloadRestrictionMessenger }
