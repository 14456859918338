import { useEffect, useRef } from "react"

const useScrollLock = (isLocked: boolean) => {
  const scrollYRef = useRef(0)
  const initialTopRef = useRef<string | null>(null)

  useEffect(() => {
    const body = document.body

    const lockScroll = () => {
      scrollYRef.current = body.style.top ? parseInt(body.style.top, 10) * -1 : window.scrollY
      initialTopRef.current = body.style.top || null

      const styles = {
        position: "fixed",
        top: `-${scrollYRef.current}px`,
        width: "100%",
        height: "100vh",
        overflowY: "hidden",
      }
      Object.assign(body.style, styles)
    }

    const unlockScroll = () => {
      const styles = {
        position: "",
        top: initialTopRef.current ?? "",
        width: "",
        height: "",
        overflowY: "",
      }
      Object.assign(body.style, styles)
      window.scrollTo(0, scrollYRef.current)
    }

    if (isLocked) {
      lockScroll()
    } else {
      unlockScroll()
    }
  }, [isLocked])
}

export { useScrollLock }
