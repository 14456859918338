import React, { FunctionComponent, ReactElement } from "react"

import { TStatusProps, TValueProps } from "@app/features/google-image-service-status/@types"
import { useGoogleImageServiceStatus } from "@app/features/google-image-service-status/hooks"
import { GoogleImageServiceStatusContext } from "@app/features/google-image-service-status/contexts"
import { getImageType } from "@app/utils"

const GoogleImageServiceStatus: FunctionComponent<TStatusProps> = ({ children }: TStatusProps): ReactElement => {
  const isAvailable = useGoogleImageServiceStatus()
  const postfix = getImageType()
  const value: TValueProps = { isAvailable, postfix }

  return <GoogleImageServiceStatusContext.Provider value={value}>{children}</GoogleImageServiceStatusContext.Provider>
}

export { GoogleImageServiceStatus }
