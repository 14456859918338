import * as React from "react"
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query"
import App from "next/app"
import GlobalProgressbar from "nextjs-progressbar"

import { GoogleImageServiceStatus } from "@app/features/google-image-service-status/components/google-image-service-status"
import { Maintenance } from "@app/features/maintenance/components"
import { isMaintenanceEnabled } from "@app/features/maintenance/requests"
import { performDomainRedirect } from "@app/features/perform-domain-redirect"

import "swiper/swiper.scss"
import "swiper/components/effect-fade/effect-fade.scss"
import "swiper/components/pagination/pagination.scss"
import "@app/styles/global.scss"

const CustomApp = ({ Component, pageProps, err }) => {
  const [queryClient] = React.useState(() => new QueryClient())

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        {pageProps.isMaintenance && (
          <Maintenance>
            <React.Fragment>
              Упс… Что-то пошло не так, но мы уже чиним.
              <br />
              Пожалуйста, попробуйте зайти позже.
            </React.Fragment>
          </Maintenance>
        )}
        {!pageProps.isMaintenance && (
          <GoogleImageServiceStatus>
            <Component {...pageProps} err={err}></Component>
          </GoogleImageServiceStatus>
        )}
        <GlobalProgressbar
          color="var(--color-black)"
          startPosition={0.3}
          stopDelayMs={200}
          height={2}
          options={{ showSpinner: false }}
        ></GlobalProgressbar>
        <div id="close-onboarding-portal"></div>
        <div id="react-floater-portal"></div>
      </Hydrate>
    </QueryClientProvider>
  )
}

CustomApp.getInitialProps = async (appContext) => {
  let appProps = await App.getInitialProps(appContext)
  let isMaintenance = false
  if (appContext.ctx.req) {
    isMaintenance = await isMaintenanceEnabled()
  }

  if (!isMaintenance) {
    // Редирект, если нужен
    const redirectResult = await performDomainRedirect(appContext.ctx)

    if (redirectResult) {
      if (redirectResult === true) {
        // Редирект уже был выполнен на сервере
        return {}
      } else {
        // Клиентский редирект
        return redirectResult
      }
    }
  }

  appProps.pageProps = { ...appProps.pageProps, isMaintenance }

  return { ...appProps }
}

export default CustomApp
