const getIosMajorVersion = (majorVersion: number): boolean => {
  if (typeof window === "undefined" || typeof navigator === "undefined") {
    return false
  }

  const userAgent = navigator.userAgent
  const match = userAgent.match(/OS (\d+)_?/)
  if (match && match[1]) {
    const currentVersion = parseInt(match[1], 10)
    return currentVersion === majorVersion
  }
  return false
}

export { getIosMajorVersion }
