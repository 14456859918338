const QA_CLASS_NAMES = {
  header: {
    print: "qa-header-print",
    download: "qa-header-download",
    favourites: "qa-header-favourites",
    share: "qa-header-share",
    logout: "qa-header-logout",
    scenes: {
      list: "qa-scenes",
      item: "qa-scene-", // qa-scene-1, qa-scene-2, qa-scene-3, ...
      hidden: "qa-scene-hidden",
      close: "qa-scenes-close",
      back: "qa-scenes-back",
    },
    burger: {
      open: "qa-mobile-menu-open",
      close: "qa-mobile-menu-close",
    },
  },

  productPreview: {
    modal: {
      photobook: "qa-modal-book",
      minibook: "qa-modal-minibook",
      canvas: "qa-modal-canvas",
      photoset: "qa-modal-photoset",
    },
    footer: {
      photobook: "qa-footer-book",
      minibook: "qa-footer-minibook",
      canvas: "qa-footer-canvas",
      photoset: "qa-footer-photoset",
    },
  },

  photo: {
    print: "qa-photo-print",
    download: "qa-photo-download",
    share: "qa-photo-share",
    favourites: "qa-photo-favourites",
    outFavourites: "qa-photo-out-favourites",
    hiding: "qa-photo-hiding",
    photo: "qa-photo", // qa-photo[0], qa-photo[2], qa-photo[3], ...
  },

  favourites: {
    email: "qa-favourites-email",
    login: "qa-favourites-login",
    close: "qa-favourites-close",
    finish: "qa-finish-favourites",
    feedbackClose: "qa-favourites-feedback-close",
    input: "qa-favourites-input",
    send: "qa-favourites-send",
  },

  scene: {
    hide: "qa-scene-hide",
    show: "qa-scene-show",
  },

  fullSize: {
    print: "qa-fullsize-print",
    download: "qa-fullsize-download",
    share: "qa-fullsize-share",
    favourites: "qa-fullsize-favourites",
    outFavourites: "qa-fullsize-out-favourites",
    hiding: "qa-fullsize-hiding",
    prev: "qa-fullsize-prev",
    next: "qa-fullsize-next",
    close: "qa-fullsize-close",
  },

  footer: {
    book: "qa-footer-book",
    minibook: "qa-footer-minibook",
    canvas: "qa-footer-canvas",
    photoset: "qa-footer-photoset",
  },

  product: {
    cover: {
      color: "qa-cover-", // qa-cover-brown, qa-cover-beige, qa-cover-gray
    },
    pages: "qa-pages-", // qa-pages-20, qa-pages-40, qa-pages-60
    modal: {
      ready: "qa-ready-layout",
      empty: "qa-empty-layout",
      service: "qa-service-layout",
      choose: "qa-modal-choose",
      close: "qa-modal-layout-close",
    },
    modalSet: {
      all: "qa-modal-all",
      scene: "qa-modal-scene",
      favourites: "qa-modal-favourites",
      clientAlbum: "qa-modal-client-album",
      close: "qa-modal-close",
      more: "qa-modal-more",
      less: "qa-modal-less",
      done: "qa-modal-done",
      alertBack: "qa-alert-back",
      alertOrder: "qa-alert-order",
    },
    choosePhotos: "qa-choose-photos",
    serviceOption: "qa-service-option",
    proceed: "qa-proceed",
  },

  editor: {
    cover: "qa-cover",
    coverMobile: "qa-cover-mobile",
    coverInput: "qa-cover-input",
    draggable: "qa-draggable",
    change: "qa-change",
    editReady: "qa-edit-ready",
    editChange: "qa-edit-change",
    order: "qa-order",
    save: "qa-save",
    send: "qa-send",
    close: "qa-save-close",
    copy: "qa-save-copy",
    back: "qa-editor-back",
  },

  page: {
    edit: "qa-page-edit",
    delete: "qa-page-delete",
    draggable: "qa-page-draggable",
    type: {
      fill: "qa-page-fullsize",
      contain: "qa-page-original",
      two_in_one_horizontal: "qa-page-two-horizontal",
      two_in_one_vertical: "qa-page-two-vertical",
      four_in_one: "qa-page-four",
    },
  },

  service: {
    order: "qa-service-order",
    close: "qa-service-close",
  },

  spread: {
    photo: "qa-photo-spread-", // qa-photo-spread-1, qa-photo-spread-2, qa-photo-spread-3, ...
    left: "qa-page-left",
    right: "qa-page-right",
    down: "qa-move-spread-down",
    up: "qa-move-spread-up",
    add: "qa-add-spread",
    delete: "qa-delete-spread",
  },

  order: {
    name: "qa-name",
    surname: "qa-surname",
    phone: "qa-phone",
    email: "qa-email",
    social: {
      whatsapp: "qa-whatsapp",
      viber: "qa-viber",
      telegram: "qa-telegram",
    },
    proceed: "qa-proceed",
    country: "qa-country",
    city: "qa-city",
    street: "qa-street",
    house: "qa-house",
    apartment: "qa-apt",
    delivery: {
      pickup_point: "qa-pvz",
      courier: "qa-door",
    },
    payment: "qa-to-payment",
    back: "qa-order-back",
  },

  quantity: {
    less: "qa-quantity-less",
    more: "qa-quantity-more",
    input: "qa-quantity-input",
  },

  gallery: {
    password: {
      input: "qa-password-input",
      show: "qa-password-show",
      button: "qa-password-button",
    },
    onboarding: {
      close: "qa-onboarding-close",
      next: "qa-onboarding-next",
      page: "qa-onboarding-page-", // qa-onboarding-page-1, qa-onboarding-page-2, qa-onboarding-page-3 ...
      back: "qa-onboarding-back",
      ok: "qa-onboarding-ok",
    },
  },
}

export { QA_CLASS_NAMES }
