import * as React from "react"

interface Options {
  isImageLoaded: boolean
  isImageLoadingError: boolean
  onImageLoad: () => void
  onImageError: () => void
  resetImage: () => void
  imageRef: any
}

function useImageLoading(): Options {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false)
  const [isImageLoadingError, setIsImageLoadingError] = React.useState(false)

  function onImageLoad() {
    setIsImageLoaded(true)
  }

  function onImageError() {
    setIsImageLoadingError(true)
  }

  function resetImage() {
    setIsImageLoaded(false)
    setIsImageLoadingError(false)
  }

  const imageRef = React.useRef<HTMLImageElement | null>()

  React.useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      setIsImageLoaded(true)
    }
  }, [])

  return {
    isImageLoaded,
    isImageLoadingError,
    onImageLoad,
    onImageError,
    resetImage,
    imageRef,
  }
}

export { useImageLoading }
