import * as React from "react"
import { useRouter } from "next/router"

type TRouterChangeHandler = (...evts: any[]) => void

/**
 * Вызывает callback перед началом перехода к другой странице
 *
 * @example <caption>Отобразит алерт с текстом "Start change route" перед сменой URL</caption>
 * useRouterChangeStart(() => alert("Start change route"))
 */
function useRouteChangeStart(callback: TRouterChangeHandler) {
  const router = useRouter()

  React.useEffect(() => {
    router.events.on("routeChangeStart", callback)
  }, [callback, router.events])
}

export { useRouteChangeStart }
