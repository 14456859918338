import React from "react"
import { apiRoot } from "@app/api"

function useLinkVideo(video_path: string) {
  const [linkVideo, setLinkVideo] = React.useState("")
  const NEXT_PUBLIC_URL_IMAGE_SOURCE = process.env.NEXT_PUBLIC_URL_IMAGE_SOURCE

  async function getLinkVideo({ video_path }: { video_path: string }) {
    const request = apiRoot()

    try {
      const response = await request.get(NEXT_PUBLIC_URL_IMAGE_SOURCE, {
        params: {
          video_path,
        },
      })

      setLinkVideo(response.data)
    } catch (error) {
      console.log("getLinkVideo error network", error)
    }
  }

  React.useEffect(() => {
    if (video_path) {
      getLinkVideo({ video_path })
    }
  }, [video_path])

  return linkVideo
}

export { useLinkVideo }
