import { useRouter } from "next/router"

const useAddQueryParameter = () => {
  const router = useRouter()

  const addRouterQueryParameter = (name: string, value: string) => {
    const newQuery = {
      ...router.query,
      [name]: value,
    }

    router.replace(
      {
        query: newQuery,
        pathname: router.pathname,
      },
      undefined,
      {
        shallow: true,
      }
    )
  }

  return addRouterQueryParameter
}

export { useAddQueryParameter }
