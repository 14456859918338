import React from "react"
import { apiRoot } from "@app/api"

function useLinkImage(size: number, photo_path: string, photos: any) {
  const [linkImage, setLinkImage] = React.useState("")
  const NEXT_PUBLIC_URL_IMAGE_SOURCE = process.env.NEXT_PUBLIC_URL_IMAGE_SOURCE // /photo-url ранее

  async function getLinkImage({ size, photo_path }: { size: string | number; photo_path: string }) {
    const request = apiRoot()

    try {
      const response = await request.get(NEXT_PUBLIC_URL_IMAGE_SOURCE, {
        params: {
          size,
          photo_path,
        },
      })

      setLinkImage(response.data)
    } catch (error) {
      console.log("getLinkImage error network", error)
    }
  }

  React.useEffect(() => {
    if (size && photo_path) {
      getLinkImage({ size, photo_path })
    }
  }, [photos])

  return [linkImage, linkImage !== ""]
}

export { useLinkImage }
