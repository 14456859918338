import { fetchGeneralSettings } from "@app/features/general-settings/requests"
import { REQUEST_TYPE } from "@app/api/constants"

const performDomainRedirect = async (ctx) => {
  const ctxUrl = ctx.req.url
  const domain = ctx.req.headers.host

  // Регулярное выражение для разбора домена и субдомена
  const urlParts = domain.match(/^(?<subdomain>[^.]+)\.(?<maindomain>(?:[^.]+\.)*[^.]+\.[^.]+)$/)
  const TYPE_REDIRECT = 308
  const generalSettings = await fetchGeneralSettings({ requestType: REQUEST_TYPE.SERVER, ctx, domain })

  if (generalSettings) {
    const serviceDomain = generalSettings?.serviceDomain

    if (serviceDomain && serviceDomain !== urlParts?.groups?.subdomain) {
      const { res } = ctx
      const destination = `${ctxUrl.startsWith("https") ? "https" : "http"}://${serviceDomain}.${
        urlParts.groups.maindomain
      }${ctxUrl}`

      if (res) {
        res.writeHead(TYPE_REDIRECT, {
          Location: destination,
        })
        res.end()

        // Редирект был выполнен
        return true
      } else {
        // клиентский редирект
        return {
          redirect: {
            destination: destination,
            permanent: true, // 308 redirect
          },
        }
      }
    }
  }

  return false
}

export { performDomainRedirect }
