import { useEffect, useState } from "react"

const useIsNodeMounted = () => {
  const [isNodeMounted, setIsNodeMounted] = useState(false)

  useEffect(() => {
    setIsNodeMounted(true)
  }, [])

  return isNodeMounted
}

export { useIsNodeMounted }
