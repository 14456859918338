import { getAccessTokenFromAPIAR, registerUserAR } from "features/user/requests"
import { setAccessTokenToCookies } from "features/user/utils"

async function authUser(email) {
  await registerUserAR(email)
  const accessToken = await getAccessTokenFromAPIAR(email)
  if (accessToken) {
    setAccessTokenToCookies(accessToken)

    return true
  }

  return false
}

export { authUser }
