/* disable errors originating from injected scripts such as Google Tag Manager */
function isInjectedCode(event): boolean {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames

  if (!frames || frames.length === 0) return false

  const firstFrame = frames[0]
  if (firstFrame.filename === "<anonymous>") {
    return true
  }

  const lastFrame = frames[frames.length - 1]
  if (
    typeof lastFrame.filename === "string" &&
    (lastFrame.filename === window.location.pathname ||
      (lastFrame.filename.startsWith(window.location.origin) &&
        // static file should not be considered as injected code. We use react-script currently, and all js-generated files are in this "static" directory.
        !lastFrame.filename.includes("/static/")))
  ) {
    return true
  }

  if (
    frames.some(
      (frame) =>
        typeof frame.filename === "string" &&
        (frame.filename.startsWith("https://www.googletagmanager.com") ||
          frame.filename.startsWith("https://googleads."))
    )
  ) {
    return true
  }

  return false
}

export { isInjectedCode }
