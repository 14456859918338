function normalizeURL(url: string, socialType: string = "url"): string {
  const HTTP_STRING = "http://"
  const HTTPS_STRING = "https://"
  const SOCIAL_PREFIXES = {
    whatsapp: "https://wa.me/",
    telegram: "https://t.me/",
  }

  const websiteUrl = url.replace(/ /g, "")

  if (websiteUrl.includes(HTTP_STRING) || websiteUrl.includes(HTTPS_STRING)) {
    return websiteUrl
  }

  if (socialType in SOCIAL_PREFIXES) {
    // для телефонных номеров оставляем только + и номер без лишних символов
    return `${SOCIAL_PREFIXES[socialType]}${websiteUrl.replace(/[^+\d]/g, "")}`
  }

  return `https://${url}`
}

export { normalizeURL }
