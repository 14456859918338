import * as React from "react"

const GlobalStateContext = React.createContext(null)

const GlobalStateProvider = ({ children, value }) => {
  return <GlobalStateContext.Provider value={value}>{children}</GlobalStateContext.Provider>
}

function useGlobalState() {
  const globalState = React.useContext(GlobalStateContext)

  return globalState
}

export { GlobalStateContext, GlobalStateProvider, useGlobalState }
