function canUseWebp(): boolean {
  if (process.browser) {
    // WEBP support (Safari spec)
    // mobile ios Safari version >= 14
    // desktop Safari version >= 16

    const { userAgent, appVersion } = navigator
    let isSafariSupport = false

    if (userAgent.includes("Firefox/")) {
      // Firefox
    } else if (userAgent.includes("Edg/")) {
      // Edge (Chromium)
    } else if (userAgent.includes("Chrome/")) {
      // Chrome
    } else if (userAgent.includes("Safari/")) {
      // Safari
      const versionString = appVersion.split("Version/")[1]
        ? appVersion.split("Version/")[1]
        : appVersion.split("CriOS/")[1]
      const versionNumber = versionString ? parseFloat(versionString.substring(0, 4)) : null
      const isMobile = versionString ? versionString.includes("Mobile/") : false

      console.log(`appVersion = ${appVersion}`)
      console.log(`isMobile = ${isMobile}`)
      console.log(`version = ${versionNumber}`)

      if (isMobile) {
        isSafariSupport = versionNumber >= 14
      } else {
        isSafariSupport = versionNumber >= 16
      }
    }

    // Quick WebP detection support (check encoding): reports synchroneously true on most browsers, except Safari
    const check_webp_decoding = () => {
      const elem: any = document.createElement("canvas")
      if (!!(elem.getContext && elem.getContext("2d"))) {
        return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0
      }

      return false
    }

    if (!check_webp_decoding()) {
      if (isSafariSupport) {
        console.info("WebP: Supported")
      } else {
        console.info("WebP: Unsupported")
      }

      return isSafariSupport
    } else {
      console.info("WebP: Supported (quick method)")

      return true
    }
  }
}

function getImageType(): string {
  if (canUseWebp()) return "-rw"

  return "-rj"
}

export { getImageType }
