import { useRouter } from "next/router"

const useReplaceQueryParameter = () => {
  const router = useRouter()

  return (queryKey: string, value: string) => {
    if (typeof router.query[queryKey] === "undefined") return

    router
      .replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            [queryKey]: value,
          },
        },
        undefined,
        {
          shallow: true,
        }
      )
      .catch((error) => console.log(error))
  }
}

export { useReplaceQueryParameter }
