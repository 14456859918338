import * as React from "react"

function useDisableContextMenu(ref: React.RefObject<HTMLElement>, activationFlag: boolean): void {
  React.useEffect(() => {
    function handleContextMenu(event: MouseEvent) {
      event.preventDefault()
    }

    if (ref?.current && activationFlag) {
      ref.current.addEventListener("contextmenu", handleContextMenu)
    }
  }, [ref, activationFlag])
}

export { useDisableContextMenu }
