import React from "react"

const ProductPricesContext = React.createContext<any | null>(null)

const ProductPricesProvider = ({ children, value }) => {
  const [prices, setPrices] = React.useState(value)

  return <ProductPricesContext.Provider value={{ prices, setPrices }}>{children}</ProductPricesContext.Provider>
}

function hasProductPricesContext() {
  const productPricesContext = React.useContext(ProductPricesContext)
  return productPricesContext?.products
}

function useProductPrices() {
  return React.useContext(ProductPricesContext)
}

export { ProductPricesProvider, hasProductPricesContext, useProductPrices }
