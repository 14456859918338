import { useRouter } from "next/router"
import { useEffect } from "react"

function useRouteChange(callback) {
  const router = useRouter()

  useEffect(() => {
    router.events.on("routeChangeComplete", callback)

    return () => {
      router.events.off("routeChangeComplete", callback)
    }
  }, [callback])
}

export { useRouteChange }
