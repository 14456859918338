function copyText(text: string): void {
  const textField = document.createElement("textarea")
  textField.innerText = text
  window.document.body.appendChild(textField)

  try {
    textField.select()
    document.execCommand("copy")
    textField.remove()
  } catch (error) {
    textField.remove()
  }
}

export { copyText }
