import React from "react"

// TODO: Нужно описание типов
const ProductsContext = React.createContext<any | null>(null)

const ProductGroupsProvider = ({ children, value }) => {
  const [groups, setGroups] = React.useState(value)

  return <ProductsContext.Provider value={{ groups, setGroups }}>{children}</ProductsContext.Provider>
}

function useHasProductGroupsContext() {
  const productGroupsContext = React.useContext(ProductsContext)
  return productGroupsContext?.products
}

function useProductGroups() {
  return React.useContext(ProductsContext)
}

export { ProductGroupsProvider, useHasProductGroupsContext, useProductGroups }
