const shareForDownload = async (linkImage: string, mediaFile: { name: string; contentType: string }) => {
  let imageUrl: string | null = null

  try {
    const response = await fetch(linkImage)

    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`)
    }

    const blob = await response.blob()
    imageUrl = URL.createObjectURL(blob)

    const contentType = mediaFile.contentType || blob.type
    const format = contentType.split("/")[1] || "jpeg"

    const baseName = mediaFile?.name || "gallery-photo"
    const fileName = `${baseName}.${format}`

    const file = new File([blob], fileName, { type: blob.type })

    try {
      await navigator.share({
        files: [file],
        title: "Download Image",
        text: "Here is the image you wanted to download.",
      })
      return true
    } catch (shareError) {
      if (shareError.name === "AbortError") {
        console.warn("Sharing was cancelled by the user.")
        return false
      }
      throw shareError
    }
  } catch (error) {
    console.error("Error sharing or downloading the image:", error)
    return false
  } finally {
    if (imageUrl) {
      URL.revokeObjectURL(imageUrl)
    }
  }
}

export { shareForDownload }
