import * as React from "react"

import { PrivateGalleryInterface, PublicGalleryInterface } from "@app/features/gallery/types"

interface GalleryContextInterface {
  gallery: PublicGalleryInterface | PrivateGalleryInterface
  setGallery: React.Dispatch<any>
}

const GalleryContext = React.createContext<GalleryContextInterface | null>(null)

const GalleryProvider = ({ children, value }) => {
  const [gallery, setGallery] = React.useState(value)

  return <GalleryContext.Provider value={{ gallery, setGallery }}>{children}</GalleryContext.Provider>
}

function hasGalleryContext() {
  const galleryContext = React.useContext(GalleryContext)
  return galleryContext?.gallery
}

function useGallery() {
  const { gallery, setGallery } = React.useContext(GalleryContext)

  return { gallery, setGallery }
}

export { GalleryContext, GalleryProvider, hasGalleryContext, useGallery }
