import { REQUEST_TYPE } from "@app/api/constants"
import { checkAccessToken, getAccessTokenFromCookies } from "@app/features/user/utils"

const getHeaders = (requestType, ctx) => {
  const headers = {
    "Content-Type": "application/json",
    ...(checkAccessToken(ctx) && { Authorization: `Bearer ${getAccessTokenFromCookies(ctx)}` }),
    ...(ctx?.req?.headers && { ...ctx.req.headers }),
  }

  if (requestType === REQUEST_TYPE.SERVER && headers?.host) {
    delete headers.host
  }

  return headers
}

export { getHeaders }
