function normalizeSiteURL(linkText: string): string {
  const HTTP_STRING = "http"

  if (linkText.includes(HTTP_STRING)) {
    return linkText.replace(/https?:\/\/(.+?)\/?$/, "$1")
  }

  return linkText
}

export { normalizeSiteURL }
