import { api } from "@app/api"

async function registerUserAR(email) {
  try {
    await api().post("/security/customer/register", {
      email,
    })
  } catch (error) {
    console.error(error.response)

    return null
  }
}

async function getAccessTokenFromAPIAR(email) {
  try {
    const { data } = await api().post("/security/customer/login", {
      username: email,
      password: email,
    })

    return data.token
  } catch (error) {
    console.error(error.response)

    return null
  }
}

export { registerUserAR, getAccessTokenFromAPIAR }
